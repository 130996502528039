import clipboard from 'clipboard-polyfill'; //https://github.com/lgarron/clipboard-polyfill
import React from 'react';
import Remarkable from 'remarkable';

export function renderMarkdown(markdown: string) {
	var md = new Remarkable();
	return md.render(markdown);
}

export function elipsis(string, length) {
	if (string == null) return "";
	if (string.length > length) {
		return string.substr(0, length) + "...";
	} else {
		return string;
	}
}

export function splitTwoLines(string: string, delimiter: string) {

	// attempts to split in the middle (or close).
	// the first line will have preference to be longer

	if (!string.includes(delimiter)) return <span className="split-line line-0">{string}</span>;
	var words = string.split(delimiter);

	var wordsInEachLine = (words.length <= 2 ? 2 : Math.round(words.length / 2));		//for odd numbers, rounding up will occur so first line will have more words
	var lines: string[] = [];
	var lineIndex = 0;
	var wordCount = 0;
	const MAX_LINES = 2;

	words.forEach(word => {
		lines[lineIndex] = (lines[lineIndex] ? lines[lineIndex] + delimiter: "") + word;
		wordCount++;
		if (wordCount >= wordsInEachLine && lineIndex + 1 < MAX_LINES) {
			wordCount = 0;
			lineIndex++;
		}
	});
	return lines.map((line, index) => <span key={index} className={"split-line line-" + index} >{line}</span>);

}

export function titleCase(string: string) {
	if (string == null) return "";
	let stringSplit = string.toLowerCase().split(" ");
	for (var i = 0; i < stringSplit.length; i++) {
		stringSplit[i] = stringSplit[i].charAt(0).toUpperCase() + stringSplit[i].slice(1);
	}
	return stringSplit.join(" ");
}

export function copyHtmlToClipboard(content) {
	var dt = new clipboard.DT();
	dt.setData("text/html", content.innerHTML);
	dt.setData("text", content.innerHTML);
	clipboard.write(dt);
}

export function copyTextToClipboard(content) {
	var dt = new clipboard.DT();
	dt.setData("text", content);
	clipboard.write(dt);
}

export function markupMentions(text) {

	var mentionPattern = /(@\[.*?\]\(.*?\))/g
	var displayPattern = /(@\[)(.*?)(\])/i

	if (!mentionPattern.test(text)) return <span>{text}</span>;

	var postSplit = text.split(mentionPattern);
	var postBody = postSplit.map((part, i) => {
		if (part.match(mentionPattern)) {
			var displayName = part.match(displayPattern);
			return <span className="link" key={part + "_" + i}>{displayName ? "@" + displayName[2].replace(" ", "_") : part}</span>;
		} else {
			return part;
		}
	});

	return <span>{postBody}</span>;

}

export function loremIpsum() {
	return "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
}

export function parseDateTime(date: string|Date) : Date {

	if (!date) return new Date();

	var dateFormat = /\d{2}-\d{2}-\d{4}/g;

	if (typeof date === "string" && date.match(dateFormat)) {
		var dateParts = date.split("-");
		return new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]));
	}

	var dateTimeFormat = /\d{2}-\d{2}-\d{4} \d{2}:\d{2}/g;
	if (typeof date === "string" && date.match(dateTimeFormat)) {
		var dateTimeParts = date.split("-|:| ");
		return new Date(parseInt(dateTimeParts[2]), parseInt(dateTimeParts[1]), parseInt(dateTimeParts[0]), parseInt(dateTimeParts[3]), parseInt(dateTimeParts[4]), 0, 0);
	}

	if (date instanceof Date) {
		return date;
	}

	//fall through to default parser
	return new Date(date);

}

export function formatDateTime(date: Date|string) {
	if (date instanceof Date) {
		return (date.getHours() <= 9 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() <= 9 ? '0' : '') + date.getMinutes() + " " + (date.getDate() <= 9 ? '0' : '') + date.getDate() + '-' + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) + '-' + date.getFullYear();
	} else {
		return formatDateTime(new Date(date));
	}
}

export function formatDate(date: Date|string) {
	if (date instanceof Date) {
		return (date.getDate() <= 9 ? '0' : '') + date.getDate() + '-' + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) + '-' + date.getFullYear();
	} else {
		return formatDate(new Date(date));
	}
}

export function formatDateISO(date: Date|string) {
	if (date instanceof Date) {
		return date.getFullYear() + '-' + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) + '-' + (date.getDate() <= 9 ? '0' : '') + date.getDate();
	} else {
		return formatDateISO(new Date(date));
	}
}


export function formatNumber(value, mask) {

	if ( !mask || isNaN( +value ) ) {
		return value; // return as it is.
	}

	var isNegative, result, decimal, group, posLeadZero, posTrailZero, posSeparator,
		part, szSep, integer,

	// find prefix/suffix
	len = mask.length,
	start = mask.search( /[0-9\-+#]/ ),
	prefix = start > 0 ? mask.substring( 0, start ) : '',
	// reverse string: not an ideal method if there are surrogate pairs
	str = mask.split( '' ).reverse().join( '' ),
	end = str.search( /[0-9\-+#]/ ),
	offset = len - end,
	indx = offset + ( ( mask.substring( offset, offset + 1 ) === '.' ) ? 1 : 0 ),
	suffix = end > 0 ? mask.substring( indx, len ) : '';

	// mask with prefix & suffix removed
	mask = mask.substring( start, indx );

	// convert any string to number according to formation sign.
	value = mask.charAt( 0 ) === '-' ? -value : +value;
	isNegative = value < 0 ? value = -value : 0; // process only abs(), and turn on flag.

	// search for separator for grp & decimal, anything not digit, not +/- sign, not #.
	result = mask.match( /[^\d\-+#]/g );
	decimal = ( result && result[ result.length - 1 ] ) || '.'; // treat the right most symbol as decimal
	group = ( result && result[ 1 ] && result[ 0 ] ) || ',';  // treat the left most symbol as group separator

	// split the decimal for the format string if any.
	mask = mask.split( decimal );
	// Fix the decimal first, toFixed will auto fill trailing zero.
	value = value.toFixed( mask[ 1 ] && mask[ 1 ].length );
	value = +( value ) + ''; // convert number to string to trim off *all* trailing decimal zero(es)

	// fill back any trailing zero according to format
	posTrailZero = mask[ 1 ] && mask[ 1 ].lastIndexOf( '0' ); // look for last zero in format
	part = value.split( '.' );
	// integer will get !part[1]
	if ( !part[ 1 ] || ( part[ 1 ] && part[ 1 ].length <= posTrailZero ) ) {
		value = ( +value ).toFixed( posTrailZero + 1 );
	}
	szSep = mask[ 0 ].split( group ); // look for separator
	mask[ 0 ] = szSep.join( '' ); // join back without separator for counting the pos of any leading 0.

	posLeadZero = mask[ 0 ] && mask[ 0 ].indexOf( '0' );
	if ( posLeadZero > -1 ) {
		while ( part[ 0 ].length < ( mask[ 0 ].length - posLeadZero ) ) {
			part[ 0 ] = '0' + part[ 0 ];
		}
	} else if ( +part[ 0 ] === 0 ) {
		part[ 0 ] = '';
	}

	value = value.split( '.' );
	value[ 0 ] = part[ 0 ];

	// process the first group separator from decimal (.) only, the rest ignore.
	// get the length of the last slice of split result.
	posSeparator = ( szSep[ 1 ] && szSep[ szSep.length - 1 ].length );
	if ( posSeparator ) {
		integer = value[ 0 ];
		str = '';
		offset = integer.length % posSeparator;
		len = integer.length;
		for ( indx = 0; indx < len; indx++ ) {
			str += integer.charAt( indx ); // ie6 only support charAt for sz.
			// -posSeparator so that won't trail separator on full length
			/*jshint -W018 */
			if ( !( ( indx - offset + 1 ) % posSeparator ) && indx < len - posSeparator ) {
				str += group;
			}
		}
		value[ 0 ] = str;
	}
	value[ 1 ] = ( mask[ 1 ] && value[ 1 ] ) ? decimal + value[ 1 ] : '';

	// remove negative sign if result is zero
	result = value.join( '' );
	if ( result === '0' || result === '' ) {
		// remove negative sign if result is zero
		isNegative = false;
	}

	// put back any negation, combine integer and fraction, and add back prefix & suffix
	return prefix + ( ( isNegative ? '-' : '' ) + result ) + suffix;

};

export function formatMoney(amount) {
	/*let money = parseFloat(amount);
	if (!money || isNaN(money)) money = 0;
	let magicCommaIntertingRegex = /\B(?=(\d{3})+(?!\d))/g;		//https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
	return "$ " + Math.round(money).toFixed(2).replace(magicCommaIntertingRegex, ",");*/
	return "$ " + formatNumber(amount, "#,###.00")
}

export function dayOfWeek(date: Date|string) {

	if (!date) return "";

	//if string passed, first parse to date
	if (typeof date === 'string' || date instanceof String) {
		return dayOfWeek(new Date(date));
	}

	return weekdayNames[date.getDay()];
}

let weekdayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function formatDateWithMonth(date: Date|string) {

	if (!date) return "";

	//if string passed, first parse to date
	if (typeof date === 'string' || date instanceof String) {
		return formatDateWithMonth(new Date(date));
	}

	return date.getDate() + '-' + monthNames[ date.getMonth() ].substring(0,3) + '-' + date.getFullYear();
}

const dateLabels = [
	"Today",
	"Next week",
	"Two weeks",
	"Four weeks",
	"Six weeks",
	"Eight weeks",
	"Twelve weeks",
	"3 months",
	"4 months",
	"5 months",
	"6 months",
	"7 months",
	"8 months",
	"9 months",
	"10 months",
	"11 months",
	"12 months",
	"15 months",
	"18 months",
	"2 years"
];

export function dateSpanLabels() {
	return dateLabels;
}

export function dateSpans() {

	let today = new Date();
	let nextWeek = new Date();
	nextWeek.setDate(nextWeek.getDate() + (1 + 7 - nextWeek.getDay()) % 7);	//next monday

	return [
		today,
		nextWeek,
		datePlusDays(nextWeek, 7),
		datePlusDays(nextWeek, 7 * 3),
		datePlusDays(nextWeek, 7 * 5),
		datePlusDays(nextWeek, 7 * 7),
		datePlusDays(nextWeek, 7 * 11),
		datePlusMonths(today, 3),
		datePlusMonths(today, 4),
		datePlusMonths(today, 5),
		datePlusMonths(today, 6),
		datePlusMonths(today, 7),
		datePlusMonths(today, 8),
		datePlusMonths(today, 9),
		datePlusMonths(today, 10),
		datePlusMonths(today, 11),
		datePlusMonths(today, 12),
		datePlusMonths(today, 15),
		datePlusMonths(today, 18),
		datePlusMonths(today, 24),
	];
}

export function dateLabel(date: Date) {

	let nextWeek = new Date();
	nextWeek.setDate(nextWeek.getDate() + (1 + 7 - nextWeek.getDay()) % 7);	//next monday

	let dates = dateSpans();

	if (!date) return dateLabels[0];
	let index = dates.findIndex((nextDate) => {
		return date <= nextDate; 
	});

	return dateLabels[(index >= 0 ? index : dates.length - 1)];
}

export function weekLabel(date) {

	let now = new Date();
	let DAY_MILLIS = 1000 * 60 * 60 * 24;	//milliseconds
	let DAYS_PER_WEEK = 7;	//milliseconds
	let days = Math.round((now.getTime() - date.getTime()) / DAY_MILLIS);

	if (days >= 0) {
		if (days < DAYS_PER_WEEK) {
			return "This week";
		}
		if (days < DAYS_PER_WEEK * 2) {
			return "Last week";
		}
		return Math.abs(Math.round(days/DAYS_PER_WEEK)) + " weeks past";
	} else {
		if (Math.abs(days) < DAYS_PER_WEEK) {
			return "Next week";
		}
		if (Math.abs(days) < DAYS_PER_WEEK * 2) {
			return "Next fortnight";
		}
		return (Math.abs(DAYS_PER_WEEK) + 1) + " weeks future";
	}


}

export function datePlusDays(date: Date, days: number) {
	var nextDate = new Date(date);
	nextDate.setDate(nextDate.getDate() + days);
	return nextDate;
}

export function datePlusMonths(date, months) {
	var nextDate = new Date(date);
	nextDate.setMonth(nextDate.getMonth() + months);
	return nextDate;
}

export function getMonday(date) {

	//var now = new Date();
	var day = date.getDay();

	//date.getDay() > 0 == sunday

	if(date.getDay() === 0) {
		//must be monday so return date
		return new Date().setDate(date.getDate() - 6);
	} else {
		return new Date().setDate(date.getDate() - day);
	}

}

/**
 * Compares only the date parts
 * @param d1 
 * @param d2 
 */
export function isDatesEqual(d1: Date, d2: Date) {

	return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();

}

const millisecondsPerDay = 1000 * 60 * 60 * 24;
export function daysBetween(d1: Date, d2: Date) {
	return Math.round(Math.abs(d2.getTime() - d1.getTime()) / millisecondsPerDay);
}

export function workingDaysBetweenDates(startDate: Date, endDate: Date) {

	// Validate input
	if (endDate < startDate) return 0;
	if (!endDate || !startDate) return 0;

	// Calculate days between dates
	
	startDate.setHours(0, 0, 0, 1);  					// Start just after midnight
	endDate.setHours(23, 59, 59, 999);  				// End just before midnight
	var diff = endDate.getTime() - startDate.getTime();  			// Milliseconds between datetime objects
	var days = Math.ceil(diff / millisecondsPerDay);

	// Subtract two weekend days for every week in between
	var weeks = Math.floor(days / 7);
	days -= weeks * 2;

	// Handle special cases
	var startDay = startDate.getDay();
	var endDay = endDate.getDay();

	// Remove weekend not previously removed.
	if (startDay - endDay > 1) {
		days -= 2;
	}

	// Remove start day if span starts on Sunday but ends before Saturday
	if (startDay === 0 && endDay !== 6) {
		days--;
	}

	// Remove end day if span ends on Saturday but starts after Sunday
	if (endDay === 6 && startDay !== 0) {
		days--;
	}
	/* Here is the code */
	/* Two working days and an sunday (not working day) */
	//let holidays = [];// can use with an array of holidays, eg. ['2016-05-03', '2016-05-05', '2016-05-07'];
	/*holidays.forEach(day => {
		if ((day >= d0) && (day <= d1)) {
		// If it is not saturday (6) or sunday (0), substract it
		if ((parseDate(day).getDay() % 6) != 0) {
			days--;
		}
		}
	});*/

	return days;

}