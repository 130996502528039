import React, { useEffect, useMemo, useState } from 'react';
import { Link, RouteComponentProps } from "react-router-dom";

import * as Dto from "actions/Dto";
import Routes from "actions/Routes";
import * as TimesheetsActions from "actions/TimesheetsActions";
import { IconButton } from "components/general/IconButton";
import * as Utils from "components/general/Utils";
import { TopMenuItem } from "components/layouts/navigation/TopMenuItem";
import * as Icons from 'react-bootstrap-icons';
import SessionStore from "store/SessionStore";
import AbstractDataPage, { DataPageState } from '../AbstractDataPage';
import ReportsModal from './ReportsModal';

import { TalentContextMenu } from '../users/TalentContextMenu';
import "./Timesheets.css";

const FILTER_KEY = "timesheet-filter.";
const EditTypes = {
	REPORTS: "reports",
}

interface TimesheetsPageState extends DataPageState<Dto.TimesheetDto>  {
	editType: string | null
	officeFilter?: string | null
	departmentFilter?: string | null
	statusFilter?: Dto.TimesheetStatus | null
	managerIdFilter?: number
}

export default class TimesheetsPage extends AbstractDataPage<Dto.TimesheetDto, TimesheetsPageState>  {
	
	constructor(props) {

		super(props);
		this.initialiseState(props);
		this.showFilter = false;

		this.createTimesheetDrafts();

	}

	initialiseState(props: RouteComponentProps<{ id?: string | undefined}>): TimesheetsPageState {

		let statusFilter = localStorage.getItem(FILTER_KEY + "statusFilter");

		return { 
			...this._defaultState,
			departmentFilter: localStorage.getItem(FILTER_KEY + "departmentFilter"),
			officeFilter: localStorage.getItem(FILTER_KEY + "officeFilter"),
			statusFilter: statusFilter ? Dto.TimesheetStatus[statusFilter] : undefined
		}
	}

	pagePath() {
		return Routes.talent();
	}


	createTimesheetDrafts() {
		if (!this.state.accountId) return;
		//Call the create timesheets method, if there are data then refresh.
		TimesheetsActions.timesheetsCreateDrafts(this.state.accountId,
			(data) => {
				if (data && data.length > 0) this.loadData(0);
			},
			(error) => {}
		);
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */



	loadMore() {
		if (!this.state.accountId) return;
		TimesheetsActions.timesheetsUntil(this.state.accountId, this.state.data ? this.state.data.index + 1 : 0, "today",
			(data) => { this.handleLoadMoreSuccessCallback(data)},
			(errors) => {this.handleErrorCallback(errors)}
		);
	}

	loadData(page) {

		if (!this.state.accountId) return;

		if (page < 0) page = 0;

		let filters : {name: string, value: string}[] = [];
		if (this.state.officeFilter) filters.push({name: "office", value: this.state.officeFilter});
		if (this.state.departmentFilter) filters.push({name: "department", value: this.state.departmentFilter});
		if (this.state.statusFilter) filters.push({name: "status", value: this.state.statusFilter});
		
		this.setState({loading: true});

		//Load talent. On error, just redirect to own profile page
		TimesheetsActions.timesheetsUntilWithFilter(this.state.accountId, page, "today", filters,
			(data) => { this.setState({filterLabel: this.state.quickFilter}); this.handleLoadSuccessCallback(data)},
			(errors) => {this.handleErrorCallback(errors)}
		);

	}

	handleTimesheetApprove(timesheet) {
		if (!this.state.accountId) return;
		TimesheetsActions.timesheetApprove(this.state.accountId, timesheet.id,
			(timesheet) => this.setState({data: this.replaceTimesheet(timesheet)}),
			(errors) => { this.handleErrorCallback(errors) }
		);
	}

	handleTimesheetUnapprove(timesheet) {
		if (!this.state.accountId || !window.confirm("Unapprove timesheet and return to Draft?")) return;
		TimesheetsActions.timesheetUnapprove(this.state.accountId, timesheet.id,
			(timesheet) => this.setState({data: this.replaceTimesheet(timesheet)}),
			(errors) => { this.handleErrorCallback(errors) }
		);
	}

	handleTimesheetReject(timesheet) {
		if (!this.state.accountId || !window.confirm("Reject timesheet?")) return;
		TimesheetsActions.timesheetReject(this.state.accountId, timesheet.id,
			(timesheet) => this.setState({data: this.replaceTimesheet(timesheet)}),
			(errors) => { this.handleErrorCallback(errors) }
		);
	}

	replaceTimesheet(timesheet) {
		if (!this.state.data) return null;
		var data = this.state.data;
		var index = data.values.findIndex(next => next.id === timesheet.id);
			if (index >= 0) data.values[index] = timesheet;
		return data;
	}

	edit(type) {
		this.setState({editType: type});
	}

	unedit() {
		this.setState({editType: null});
	}

	handleDownloadReport(report, fromDate, toDate) {
		this.setState({"editType": null});
		let params = "name=" + report + (fromDate ? "&fromDate=" + Utils.formatDateISO(new Date(fromDate)) : "")  + (toDate ? "&toDate=" + Utils.formatDateISO(new Date(toDate)) : "");
		let downloadUrl = Routes.timesheetReports(this.state.accountId, params);
		
		if (report === Dto.ReportTypeEnum.ASSIGNMENT) {
			downloadUrl = Routes.assignmentsReport(this.state.accountId, params);
		}
		window.open(downloadUrl, '_blank');
	}

	handleFilterChange(name: string, value?: string) {

		switch (name) {
			case "departmentFilter":
				this.setState({departmentFilter: value}, () => this.loadData(0));
				break;
			case "officeFilter":
				this.setState({officeFilter: value}, () => this.loadData(0));
				break;
			case "statusFilter":
				this.setState({statusFilter: value ? Dto.TimesheetStatus[value] : undefined}, () => this.loadData(0));
				break;
			default:
				break;
		}

		//Store in local storage
		if (value) {
			localStorage.setItem(FILTER_KEY + name, value);
		} else {
			localStorage.removeItem(FILTER_KEY + name);
		}
		
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	title() {
		return "Timesheets";
	}

	advancedFilterLabel() {
		return "";
	}

	renderActionsDropdown() {

		if (SessionStore.isManagerRole()) {
			return (

				<React.Fragment>
					<div className="dropdown">
						<button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
							<IconButton icon={<Icons.GearFill />} className="invert" />
						</button>
						<div className="dropdown-menu dropdown-menu-right">
							<button className="dropdown-item disabled" onClick={() => this.setState({"editType": EditTypes.REPORTS})}>Reports</button>
							<div className="dropdown-divider"></div>
							<h6 className="dropdown-header">Filter</h6>
							<button className="dropdown-item disabled" >By Office (off)</button>
						</div>
					</div>

					<ReportsModal show={this.state.editType === EditTypes.REPORTS}
						onCancel={() => this.setState({"editType": null})} onDownload={(report, fromDate, toDate) => this.handleDownloadReport(report, fromDate, toDate)} />

				</React.Fragment>

			);
		} else {
			return null;
		}
	}

	renderFilterControls() : JSX.Element {
		return (
			<>
				<div className="">Filter: </div>
				{SessionStore.getOffices() && SessionStore.getOffices().length > 0 ?
					<div className="dropdown">
						<button className="pill search-criteria dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
							Office: {this.state.officeFilter ? this.state.officeFilter : "none" }
						</button>
						<div className="dropdown-menu dropdown-menu-right">
							<button className="dropdown-item" onClick={() => this.handleFilterChange("officeFilter", undefined)}>(None)</button>
							{SessionStore.getOffices().map(office => <button className="dropdown-item" onClick={() => this.handleFilterChange("officeFilter", office)}>{office}</button>)}
						</div>
					</div>
					: null
				}

				{SessionStore.getDepartments() && SessionStore.getDepartments().length > 0 ?
					<div className="dropdown">
						<button className="pill search-criteria dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
							Department: {this.state.departmentFilter ? this.state.departmentFilter : "none" }
						</button>
						<div className="dropdown-menu dropdown-menu-right">
							<button className="dropdown-item" onClick={() => this.handleFilterChange("departmentFilter", undefined)}>(None)</button>
							{SessionStore.getDepartments().map(department => <button className="dropdown-item" onClick={() => this.handleFilterChange("departmentFilter", department)}>{department}</button>)}
						</div>
					</div>
					: null
				}


				<div className="dropdown">
					<button className="pill search-criteria dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
						Status: {this.state.statusFilter ? Utils.titleCase(this.state.statusFilter) : "none" }
					</button>
					<div className="dropdown-menu dropdown-menu-right">
						<button className="dropdown-item" onClick={() => this.handleFilterChange("statusFilter", undefined)}>(None)</button>
						{Object.keys(Dto.TimesheetStatus).map(status => <button className="dropdown-item" onClick={() => this.handleFilterChange("statusFilter", status)}>{Utils.titleCase(status)}</button>)}
					</div>
				</div>
					


				
			</>
		);
	}

	contextMenuItems() {
		return (
			<React.Fragment>
				<TopMenuItem label="Timesheets" to={Routes.timesheets()} active={true}/>
				<TalentContextMenu user={SessionStore.getUser()} path={this.props.location.pathname}/>
			</React.Fragment>
		);
	}

	getDateMapFromTimesheets() : Date[] {
		
		var dateMap: Date[] = [];

		if (this.state.data) {
			this.state.data.values.forEach((timesheet) => {
				if (!dateMap.includes(timesheet.date)) dateMap.push(timesheet.date);
			});
		}

		return dateMap;
	}

	renderData() {

		if (this.state == null || !this.state.data || this.state.data.values.length <= 0) return this.renderNoRecordsFound();

		const dateMap = this.getDateMapFromTimesheets();

		const output = dateMap.map((date) => {

			return (
				<TimesheetsForDate 
					timesheets={this.state.data?.values || []} 
					date={date} 
					onApprove={(timesheet) => this.handleTimesheetApprove(timesheet)}
					onReject={(timesheet) => this.handleTimesheetReject(timesheet)}
					onUnapprove={(timesheet) => this.handleTimesheetUnapprove(timesheet)}
				/>
			);

		});

		return (
			<>
				{output}
			</>
		);

	}

	renderDataForStatus(status) {
		if (!this.state.data) return null;
		return this.state.data.values.map((timesheet, index) => {

			if (timesheet.status === status) {
				return this.renderDataItem(timesheet);
			}

			return null;

		});
	}



	renderDataItem(timesheet) {

		return (
			<TimesheetTile key={"entries-" + timesheet.id}
				timesheet={timesheet} 
				onApprove={(timesheet) => this.handleTimesheetApprove(timesheet)}
				onReject={(timesheet) => this.handleTimesheetReject(timesheet)}
				onUnapprove={(timesheet) => this.handleTimesheetUnapprove(timesheet)}
			/>
		);

	}


}

interface TimesheetTileProps {
	timesheet: Dto.TimesheetDto
	onApprove: (timesheet: Dto.TimesheetDto) => void
	onReject: (timesheet: Dto.TimesheetDto) => void
	onUnapprove: (timesheet: Dto.TimesheetDto) => void
}
function TimesheetTile(props: TimesheetTileProps) {

	const findUniqueAssignments = (timesheet: Dto.TimesheetDto) => {

		let assignments: Dto.TimesheetAssignmentDto[] = [];
	
		//find unique assignments
		timesheet.entries.forEach((entry) => {
			if (entry.assignment && !(entry.assignment.id in assignments)) assignments[entry.assignment.id] = entry.assignment;
		});
	
		return assignments;

	}

	const findUniqueLeave = (timesheet: Dto.TimesheetDto) => {

		let leave: Dto.LeaveTypeDto[] = [];
	
		//find unique assignments
		timesheet.entries.forEach((entry) => {
			if (entry.leaveType && !(entry.leaveType?.id in leave)) leave[entry.leaveType.id] = entry.leaveType;
		});
		
		return leave;

	}

	const totals = (entries: Dto.TimesheetEntryDto[]) => {

		let total = entries.reduce((total, entry) => {
			let quantity = entry.quantity;
			return total + (isNaN(quantity) ? 0 : quantity);
		}, 0);
		return total;

	}


	const totalsNonBillable = (entries: Dto.TimesheetEntryDto[]) => {

		let total = entries.reduce((total, entry) => {
			let quantity = !entry.billable ? entry.quantity : 0;
			return total + (isNaN(quantity) ? 0 : quantity);
		}, 0);
		return total;

	}

	const entries = (timesheet: Dto.TimesheetDto) => {
		let assignments = findUniqueAssignments(timesheet);

		let assignmentsElement = assignments.map((assignment) => {
			
				return (
					<div key={"assignment-" + assignment.id} className="entry">
						<span className="brief-status">{briefIcon(assignment)}</span>
						<span className="title">{assignment.project.title} - {assignment.title}</span>
						<span className="amount">{totals(timesheet.entries.filter(entry => entry.assignment && entry.assignment.id === assignment.id))}</span>
					</div>
				)
			}
		);
		let leaves = findUniqueLeave(timesheet);
		let leaveElement = leaves.map((leaveType) => {
				return (
					<div key={"leaveType-" + leaveType.id} className="entry">
						<span className="brief-status"></span>
						<span className="title">{leaveType.title}</span>
						<span className="amount">{totals(timesheet.entries.filter(entry => entry.leaveType && entry.leaveType.id === leaveType.id))}</span>
					</div>
				)
			}
		);
		return (
			<>
				{assignmentsElement}
				{leaveElement}
			</>
		);
	}

	const renderStatusRating = (statusRating: Dto.TimesheetRating) => {
		if (statusRating) {
			switch (statusRating) {
				case Dto.TimesheetRating.GREEN:
					return (<div className="smiley happy selected"><Icons.EmojiSmile /></div>);
				case Dto.TimesheetRating.AMBER:
					return (<div className="smiley frown selected"><Icons.EmojiNeutral /></div>);
				case Dto.TimesheetRating.RED:
					return (<div className="smiley sad selected"><Icons.EmojiFrown /></div>);
				default:
					return (<div className="smiley"><Icons.EmojiNeutral /></div>);
			}
		}
		return <div className="smiley"><Icons.EmojiNeutral /></div>;
	}

	const details = (timesheet: Dto.TimesheetDto) => {
		return(
			<>
				<div className="thumbnail">
					<Link to={Routes.timesheetsForUser(timesheet.user.id, timesheet.date)}><img className="profile-thumbnail" src={timesheet.user.photoThumbnailUrl ? timesheet.user.photoThumbnailUrl : "/media/user@4x.png"} alt="User"/></Link>
					<div className={"badge badge-pill status-" + timesheet.status}>{Utils.titleCase(timesheet.status)}</div><br/>
				</div>

				<div className="details" >
					

					<h5 className="name" title={timesheet.user.firstname + " " + timesheet.user.surname}>
						<Link to={Routes.timesheetsForUser(timesheet.user.id, timesheet.date)}>
							<div className="firstname">{timesheet.user.firstname}</div> <div className="surname">{timesheet.user.surname}</div>
						</Link>
						<div className="department">{timesheet.user.department}</div>
					</h5>
					
					{renderTotals()}
					<div className="status-report">
						<div className="status-rating">
							{renderStatusRating(timesheet.statusRating)}
						</div>
						<div className="status-report-body">
							<div dangerouslySetInnerHTML={{__html: Utils.renderMarkdown(timesheet.statusReport)}} />
						</div>
					</div>					
					
				</div>
				<div className="entries">
					{entries(props.timesheet)}
					
				</div>
			</>
		)
	}

	const renderNonBillable = () => {

		let nonBillable = totalsNonBillable(props.timesheet.entries);

		if (nonBillable) {
			return (
				<div className="nonbillable quantity">
					<span className="amount">{Utils.formatNumber(totalsNonBillable(props.timesheet.entries), "0.#")}</span><span className="units">Non-billable Days</span>
				</div>
			);
		}

		return null;

	}

	const renderTotals = () => {
		return (
			<div className="totals">
						
				{renderNonBillable()}

				<div className="billable quantity">
					<span className="amount">{Utils.formatNumber(totals(props.timesheet.entries), "0.#")}</span><span className="units">Total Days</span>
				</div>

			</div>
		)
	}

	const renderTools = () => {
		switch (props.timesheet.status) {
			case Dto.TimesheetStatus.APPROVED:
				return (
					<>
						<button className="btn btn-warning" onClick={() => props.onUnapprove(props.timesheet)}>Unapprove</button>
						<Link to={Routes.timesheetsForUser(props.timesheet.user.id, props.timesheet.date)}><button className="btn" >View</button></Link>
						<button className="btn" onClick={() => props.onReject(props.timesheet)}>Reject</button>
					</>
				)
			case Dto.TimesheetStatus.SUBMITTED:
				return (
					<>
						<button className="btn btn-success" onClick={() => props.onApprove(props.timesheet)}>Approve</button>
						<Link to={Routes.timesheetsForUser(props.timesheet.user.id, props.timesheet.date)}><button className="btn" >View</button></Link>
						<button className="btn" onClick={() => props.onReject(props.timesheet)}>Reject</button>
					</>
				)	
			default:
				return (
					<>
						<button className="btn btn-success disabled"  onClick={() => alert("Timesheet not submitted")}>Approve</button>
						<Link to={Routes.timesheetsForUser(props.timesheet.user.id, props.timesheet.date)}><button className="btn" >View</button></Link>
					</>
				)	

		}
	}

	return (
		<div className={"timesheet status-" + props.timesheet.status} key={props.timesheet.id}>

			{details(props.timesheet)}
			
			<div className="tools">
							
				{SessionStore.isManagerRole() ?
					renderTools()
					: null
				}

			</div>
		</div>
	);

}


export const briefIcon = (assignment: Dto.TimesheetAssignmentDto) => {

	if (!assignment.briefState) return null;

	switch (assignment.briefState) {
		case Dto.AssignmentBriefState.APPROVED:
			return <Link to={Routes.project(assignment.project.id)}><Icons.CheckCircle title="Assignment brief approved"/></Link>;
		case Dto.AssignmentBriefState.DRAFT:
			return <Link to={Routes.project(assignment.project.id)}><Icons.ExclamationTriangle title="Assignment brief in draft"/></Link>;
		case Dto.AssignmentBriefState.REJECTED:
			return <Link to={Routes.project(assignment.project.id)}><Icons.ExclamationTriangle title="Assignment brief rejected"/></Link>;
		case Dto.AssignmentBriefState.SUBMITTED:
			return <Link to={Routes.project(assignment.project.id)}><Icons.ExclamationTriangle title="Assignment brief submitted"/></Link>;
		default:
			return null;
	}

}

export const briefStatusPill = (assignment: Dto.TimesheetAssignmentDto) => {

		//Brief is not complete, so show brief status
		switch (assignment.briefState) {
			case Dto.AssignmentBriefState.DRAFT:
				return <Link to={Routes.project(assignment.project.id)} className={"badge badge-pill status status-brief DRAFT" } >Draft Brief</Link>
			case Dto.AssignmentBriefState.SUBMITTED:
				return <Link to={Routes.project(assignment.project.id)} className={"badge badge-pill status status-brief SUBMITTED" } >Brief submitted</Link>
			case Dto.AssignmentBriefState.REJECTED:
				return <Link to={Routes.project(assignment.project.id)} className={"badge badge-pill status status-brief REJECTED" } >Brief rejected</Link>
			default:
				return null;
		}

}
interface TimesheetsForDateProps {
	timesheets: Dto.TimesheetDto[]
	date: Date
	onApprove: (timesheet: Dto.TimesheetDto) => void
	onReject: (timesheet: Dto.TimesheetDto) => void
	onUnapprove: (timesheet: Dto.TimesheetDto) => void
}
function TimesheetsForDate(props: TimesheetsForDateProps) {

	const [show, setShow] = useState(false);

	const filteredTimesheets = useMemo(
		() => props.timesheets.filter((timesheet) => timesheet.date === props.date),
		[props.timesheets, props.date]
	);

	const weekLabel = useMemo(
		() => Utils.weekLabel(new Date(props.date)),
		[props.date]
	);

	useEffect(() => {
		const lastWeek = Utils.datePlusDays(new Date(), - 5);
		const lastFortnight = Utils.datePlusDays(new Date(), - 14);
		//If date is in the range of last week, set show to true
		if (new Date(props.date) <= lastWeek && new Date(props.date) >= lastFortnight) {
			setShow(true);
		}
	}, [props.date]);

	return (
		<>
			<div key={"date-divider-" + props.date } className={"data-divider " + weekLabel.toLowerCase().replace(/ /gi, "-") }>
				<div className="title">
					<div className="date">{Utils.formatDateWithMonth(props.date)}</div>
					<div className="date-label">{weekLabel}</div>
				</div>
				<div className="actions">
					{show ? <IconButton title="Show less" className="show-more" onClick={() => setShow(false)} icon={<Icons.ChevronUp />}  /> :
						<IconButton title="Show more" className="show-more" onClick={() => setShow(true)} icon={<Icons.ChevronDown />}  />
					}
				</div>
			</div>
			{show ? filteredTimesheets.map((timesheet) => <TimesheetTile key={"entries-" + timesheet.id}
				timesheet={timesheet} 
				onApprove={(timesheet) => props.onApprove(timesheet)}
				onReject={(timesheet) => props.onReject(timesheet)}
				onUnapprove={(timesheet) => props.onUnapprove(timesheet)}
			/>) : null}
	</>
	);

	

}